import React, { useState } from 'react';
import LightGallery from 'lightgallery/react';
import { Asset } from '../../models/asset-model';
import 'lightgallery/scss/lightgallery.scss';
import './lazy-card-image-component.scss';

export default function LazyCardImageComponent(props: {
  assetParam: Asset;
  defaultImagePath: string;
}) {
  const { assetParam, defaultImagePath } = props;

  const [asset] = useState<Asset>(assetParam);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      className="card-image"
      title={asset.assetId}
      style={{
        opacity: isLoaded ? '1' : '0.7',
        backgroundImage: isLoaded ? '' : `url(${defaultImagePath})`,
      }}
    >
      <LightGallery
        licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
        startAnimationDuration={0}
        speed={0}
        toggleThumb={false}
        backdropDuration={200}
        slideEndAnimation
        controls={false}
        counter={false}
        download={false}
      >
        <img
          src={asset.imageUrl}
          loading="lazy"
          alt=""
          title={asset.assetId}
          style={{ width: isLoaded ? '100%' : '1px' }}
          onLoad={() => {
            setIsLoaded(true);
          }}
        />
      </LightGallery>
    </div>
  );
}
