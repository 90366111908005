import React from 'react';
import { Link } from 'react-router-dom';
import imgSOG from '../../assets/images/in-the-spotlight/in-the-spotlight-spell-of-genesis.png';
import imgCT from '../../assets/images/in-the-spotlight/in-the-spotlight-casa-tookan.png';
import imgNFTStore from '../../assets/images/in-the-spotlight/in-the-spotlight-ntf-store.jpg';
import './spotlight-component.scss';

export default function SpotlightComponent(): JSX.Element {
  return (
    <div id="spotlight-component">
      <div className="row">
        <h2 className="font-weight-black">In the spotlight</h2>
        <div className="col-12 col-sm-4">
          <div className="card">
            <div className="card-image">
              <img src={imgCT} className="card-img" alt="Casa Tookan wallet" />
            </div>
            <div className="card-body">
              <div className="title-line">
                <h3>Casa Tookan</h3>
              </div>
              <Link
                className="button"
                to={{ pathname: 'https://crystalsuite.com/casa-tookan-wallet/' }}
                target="_blank"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="card">
            <div className="card-image">
              <img src={imgNFTStore} className="card-img" alt="Bind wallet" />
            </div>
            <div className="card-body">
              <div className="title-line">
                <h3>NFT Store</h3>
              </div>
              <Link
                className="button"
                to={{ pathname: 'https://crystalsuite.com/store/' }}
                target="_blank"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-4">
          <div className="card">
            <div className="card-image">
              <img src={imgSOG} className="card-img" alt="Spells of Genesis" />
            </div>
            <div className="card-body">
              <div className="title-line">
                <h3>Spell of Genesis</h3>
              </div>
              <div>
                <Link
                  className="button"
                  to={{ pathname: 'https://spellsofgenesis.com' }}
                  target="_blank"
                >
                  Learn More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
