import { User } from '../../models/user-model';
import ApiService from '../api-service';
import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

export default class FirstOasisService extends BaseWalletService implements WalletService {
  private ethWallet: string;

  private apiService = new ApiService();

  init = async (
    newChainHandler?: (chainId: number) => void,
    newNetworkHandler?: (networkId: number) => void,
    newAccountHandler?: (newAccounts: string) => void
  ) => {
    super.init(undefined, undefined, newAccountHandler);
  };

  connect = async (): Promise<void> => {
    const user: User = await this.apiService.getUserAddressesAsync(this.ethWallet);
    if (user) {
      const foAddressUser = user?.data?.addresses?.find((x) => x.blockchain === 'firstOasisKlaytn');
      const address = foAddressUser ? foAddressUser?.address : '';

      this.handleNewAccounts(address);
    }
  };

  isWalletInstalled = (): boolean => {
    return true;
  };

  getAccounts = async (): Promise<string> => {
    throw new Error('Method not implemented.');
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  personalSign = (message: string, password?: string): Promise<string> => {
    throw new Error('Method not implemented.');
  };

  getName = (): string => {
    return 'FirstOasis';
  };

  setEthWallet(ethWallet: string): void {
    if (ethWallet) {
      this.ethWallet = ethWallet;
    }
  }
}
