import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { WalletOrb } from '../../models/wallet-orb-model';
import './withdraw-modal-4-component.scss';
import MetaMaskService from '../../services/wallet/meta-mask-service';
import IUserAddresses from '../../models/user-addresses-model';

interface IWithdrawModalParams {
  closeWithdrawModal4: MouseEventHandler<HTMLButtonElement>;
  gasRequired: number | undefined;
  orb: WalletOrb | undefined;
  returnToWithdrawModal3: () => void;
  userAddresses: IUserAddresses;
  withdraw: (signature: string) => void;
  withdrawAddress: string | undefined;
  withdrawBlockchain: string | undefined;
  withdrawError: boolean;
  withdrawErrorMessage: string | undefined;
  withdrawModal4Visibility: boolean;
  withdrawInProgress: boolean;
  withdrawOrb: WalletOrb | undefined;
}

export default function WithdrawModal4(props: IWithdrawModalParams): JSX.Element {
  const [isOpenState, setOpenState] = useState(false);
  const {
    closeWithdrawModal4,
    gasRequired,
    orb,
    returnToWithdrawModal3,
    userAddresses,
    withdraw,
    withdrawAddress,
    withdrawBlockchain,
    withdrawError,
    withdrawErrorMessage,
    withdrawModal4Visibility,
    withdrawInProgress,
    withdrawOrb,
  } = props;
  const metaMaskService: MetaMaskService = new MetaMaskService();

  async function signMessageAndWithdraw() {
    const signMessage = `withdraw:${withdrawOrb?.asset.id}:${withdrawAddress}:${userAddresses.ethereum}`;
    await metaMaskService.personalSign(signMessage).then((response) => {
      withdraw(response);
    });
  }

  useEffect(() => {
    setOpenState(withdrawModal4Visibility);
  }, [withdrawModal4Visibility]);

  return (
    <Modal
      id="withdraw-modal-4"
      show={isOpenState}
      onHide={closeWithdrawModal4}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Withdrawal on {withdrawBlockchain}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="orb-image">
          <img
            src={orb?.asset.image}
            className="w-100 h-100 p-1 pb-3 d-flex justify-content-center"
            alt="Orb to withdraw"
          />
        </div>
        {!withdrawInProgress ? (
          <div>
            {!withdrawError ? (
              <>
                <p>You are withdrawing this Orb on the following blockchain:</p>
                <h5>{withdrawBlockchain}</h5>
                <p>Address:</p>
                <h5>{withdrawAddress}</h5>
                <p>Fee:</p>
                <h5>{gasRequired} BCYM</h5>
                <div id="buttons">
                  <Button
                    onClick={async () => {
                      await signMessageAndWithdraw();
                    }}
                  >
                    OK
                  </Button>
                </div>
                <p>
                  ATTENTION: A card withdrawn on one blockchain cannot be transferred to another
                  chain. Once performed, the transaction is irreversible. The fee is not refundable.
                </p>
              </>
            ) : (
              <p>{withdrawErrorMessage}</p>
            )}
          </div>
        ) : (
          <div id="spinner-container">
            <Spinner animation="grow" />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => returnToWithdrawModal3()}>
          Back
        </Button>
        <Button variant="secondary" onClick={closeWithdrawModal4}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
