const apiRoute = process.env.REACT_APP_API_ROUTE;
const bitcrystalRoute = process.env.REACT_APP_BITCRYSTALS_ROUTE;

export const COLLECTIONS_URL = `${apiRoute}api/v1/collections`;
export const TRANSACTIONS_URL = `${apiRoute}api/v1/events{address}`;
export const COLLECTION_DETAILS_URL = `${apiRoute}api/v1/collections/`;
export const TRANSACTION_FOR_COLLECTION_URL = `${apiRoute}api/v1/events{address}?collection=`;
export const USER_ADDRESSES = `${apiRoute}api/v1/user/get/`;
export const WALLET_BALANCE = `${apiRoute}api/v1/balances/`;
export const USER_GAMES = `${bitcrystalRoute}api/v1/user{address}/app/`;
export const CAROUSELS = `${bitcrystalRoute}api/v1/collections/carousels`;
export const WITHDRAW_VERIFY_URL = `${apiRoute}api/v1/firstOasis/withdrawVerify`;
export const WITHDRAW_URL = `${apiRoute}api/v1/firstOasis/withdraw`;
