import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Wallet from '../../models/wallet-model';
import FirstOasisService from '../../services/wallet/first-oasis-service';
import KaikasService from '../../services/wallet/kaikas-service';
import MetaMaskService from '../../services/wallet/meta-mask-service';
import WebXcpService from '../../services/wallet/web-xcp-service';
import Web3Service from '../../services/wallet/web3-service';
import WalletDropDown from '../wallet-drop-down/wallet-drop-down-control';
import './wallet-menu-component.scss';

const initialState: Wallet = {
  address: '',
  isInstalled: false,
  chain: 0,
};

const web3Service: Web3Service = new Web3Service();
const metaMaskService: MetaMaskService = new MetaMaskService();
const kaikasService: KaikasService = new KaikasService();
const webXcpService: WebXcpService = new WebXcpService();
const firstOasisService: FirstOasisService = new FirstOasisService();
const isInsideCasaTookan = !!window.webXCP;

export default function WalletMenu(): JSX.Element {
  const [metamaskWallet, setMetamaskWallet] = useState<Wallet>({ ...initialState, chain: 1 });
  const [kaikasWallet, setKaikasWallet] = useState<Wallet>({ ...initialState, chain: 8217 });
  const [web3Wallet, setWeb3Wallet] = useState<Wallet>({ ...initialState, chain: 1 });
  const [webXcpWallet, setWebXcpWallet] = useState<Wallet>({ ...initialState, chain: -1 });
  const [firstOasisWallet, setFirstOasisWallet] = useState<Wallet>({
    ...initialState,
    chain: -2,
  });

  function metamaskChainChange(chainId: number) {
    metaMaskService.logInfo(`metamaskChainChange: ${chainId}`);

    setMetamaskWallet((prevState) => ({ ...prevState, chain: chainId }));
  }

  function metamaskNetworkChange(networkId: number) {
    metaMaskService.logInfo(`metamaskNetworkChange: ${networkId}`);
  }

  function metamaskAccountChange(newAccounts: string) {
    metaMaskService.logInfo(`metamaskAccountChange: ${newAccounts}`);

    const account = Array.isArray(newAccounts) ? newAccounts[0] : newAccounts;
    if (account) {
      setMetamaskWallet((prevState) => ({ ...prevState, address: account }));
      firstOasisService.setEthWallet(account);
      firstOasisService.connect();
    } else {
      setMetamaskWallet((prevState) => ({ ...prevState, address: '' }));
      firstOasisService.setEthWallet('');
    }
  }

  function metamaskDisconnect() {
    setMetamaskWallet((prevState) => ({ ...prevState, address: '' }));
  }

  function kaikasChainChange(chainId: number) {
    kaikasService.logInfo(`kaikasChainChange: ${chainId}`);

    setKaikasWallet((prevState) => ({ ...prevState, chain: chainId }));
  }

  function kaikasNetworkChange(networkId: number) {
    kaikasService.logInfo(`kaikasNetworkChange: ${networkId}`);
  }

  function kaikasAccountChange(newAccounts: string) {
    kaikasService.logInfo(`kaikasAccountChange: ${newAccounts}`);

    const account = Array.isArray(newAccounts) ? newAccounts[0] : newAccounts;
    setKaikasWallet((prevState) => ({ ...prevState, address: account }));
  }

  function kaikasDisconnect() {
    setKaikasWallet((prevState) => ({ ...prevState, address: '' }));
  }

  function web3AccountChange(newAccounts: string) {
    web3Service.logInfo(`web3AccountChange: ${newAccounts}`);

    if (newAccounts) {
      const account = Array.isArray(newAccounts) ? newAccounts[0] : newAccounts;
      setWeb3Wallet((prevState) => ({ ...prevState, address: account, isInstalled: true }));
      firstOasisService.setEthWallet(account);
      firstOasisService.connect();
    } else {
      setWeb3Wallet((prevState) => ({ ...prevState, address: '', isInstalled: false }));
      firstOasisService.setEthWallet('');
    }
  }

  function web3Disconnect() {
    setWeb3Wallet((prevState) => ({ ...prevState, address: '' }));
  }

  function webXcpAccountChange(newAccounts: string) {
    webXcpService.logInfo(`webXcpAccountChange: ${newAccounts}`);

    setWebXcpWallet((prevState) => ({ ...prevState, address: newAccounts }));
  }

  function firstOasisAccountChange(newAccounts: string) {
    firstOasisService.logInfo(`firstOasisAccountChange: ${newAccounts}`);
    if (!newAccounts) {
      setFirstOasisWallet((prevState) => ({ ...prevState, address: '', isInstalled: false }));
    }

    setFirstOasisWallet((prevState) => ({ ...prevState, address: newAccounts, isInstalled: true }));
  }

  // Web3
  useEffect(() => {
    async function init() {
      await web3Service.init(undefined, undefined, web3AccountChange, web3Disconnect);

      const isWeb3Installed = web3Service.isWalletInstalled();
      if (isWeb3Installed) {
        const account = await web3Service.getAccounts();
        setWeb3Wallet((prevState) => ({
          ...prevState,
          chain: 1,
          networkId: 1,
          address: account,
          isInstalled: isWeb3Installed,
        }));
      }
    }

    init();
  }, []);

  // MetaMask
  useEffect(() => {
    async function init() {
      await metaMaskService.init(
        metamaskChainChange,
        metamaskNetworkChange,
        metamaskAccountChange,
        metamaskDisconnect
      );

      const isMetaMaskInstalled = metaMaskService.isWalletInstalled();
      if (isMetaMaskInstalled) {
        const account = await metaMaskService.getAccounts();

        setMetamaskWallet((prevState) => ({
          ...prevState,
          address: account,
          isInstalled: isMetaMaskInstalled,
        }));
      }
    }

    init();
  }, []);

  // Kaikas
  useEffect(() => {
    async function init() {
      await kaikasService.init(
        kaikasChainChange,
        kaikasNetworkChange,
        kaikasAccountChange,
        kaikasDisconnect
      );

      const isWalletInstalled = kaikasService.isWalletInstalled();
      if (isWalletInstalled) {
        const account = await kaikasService.getAccounts();
        setKaikasWallet((prevState) => ({
          ...prevState,
          address: account,
          isInstalled: isWalletInstalled,
        }));
      }
    }

    init();
  }, []);

  // WebXcp
  useEffect(() => {
    async function init() {
      await webXcpService.init(undefined, undefined, webXcpAccountChange);

      const isWebXcpInstalled = webXcpService.isWalletInstalled();
      if (isWebXcpInstalled) {
        const account = await webXcpService.getAccounts();
        setWebXcpWallet((prevState) => ({
          ...prevState,
          address: account,
          isInstalled: isWebXcpInstalled,
        }));
      }
    }

    init();
  }, []);

  // FirstOasis
  useEffect(() => {
    firstOasisService.init(undefined, undefined, firstOasisAccountChange);
  }, []);

  return (
    <Dropdown className="d-inline mx-2">
      <Dropdown.Toggle>
        <span>Account</span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        {metamaskWallet && metamaskWallet.isInstalled
          ? WalletDropDown(metaMaskService, metamaskWallet)
          : WalletDropDown(web3Service, web3Wallet)}
        {isInsideCasaTookan ? '' : WalletDropDown(kaikasService, kaikasWallet)}
        {isInsideCasaTookan && firstOasisWallet.address
          ? WalletDropDown(firstOasisService, firstOasisWallet)
          : ''}
        {WalletDropDown(webXcpService, webXcpWallet)}
      </Dropdown.Menu>
    </Dropdown>
  );
}
