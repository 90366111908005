import axios, { CancelTokenSource } from 'axios';
import {
  CAROUSELS,
  COLLECTIONS_URL,
  COLLECTION_DETAILS_URL,
  TRANSACTIONS_URL,
  TRANSACTION_FOR_COLLECTION_URL,
  USER_ADDRESSES,
  USER_GAMES,
  WALLET_BALANCE,
  WITHDRAW_VERIFY_URL,
  WITHDRAW_URL,
} from '../consts';
import { CarouselItem } from '../models/carousel-item-model';
import { CollectionDetails } from '../models/collection-details-model';
import Collections from '../models/collections-model';
import { Transactions } from '../models/transactions-model';
import { UserGames } from '../models/user-games-model';
import { User } from '../models/user-model';
import { WalletBalance } from '../models/wallet-balance-model';
import IWithdrawVerificationResponse from '../models/withdraw-verification-response';
import { IDepositExecutorResponse } from '../models/deposit-executor-response';
import IWithdrawResponse from '../models/withdraw-response';

export default class ApiService {
  public getCollectionsAsync = async (): Promise<Collections> => {
    try {
      const response = await axios.get(COLLECTIONS_URL);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  public getTransactionsAsync = async (
    collectionName?: string,
    address?: string
  ): Promise<Transactions> => {
    let url = collectionName ? TRANSACTION_FOR_COLLECTION_URL + collectionName : TRANSACTIONS_URL;
    url = url.replace('{address}', address ? `/${address}` : '');
    try {
      const response = await axios.get(url);
      const result: Transactions = response.data;
      return result;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  public getCollectionDetailsAsync = async (collectionName: string): Promise<CollectionDetails> => {
    try {
      const response = await axios.get(COLLECTION_DETAILS_URL + collectionName);

      // Fix for the API inconsistency
      if (!response.data) {
        return {} as CollectionDetails;
      }

      const result = response.data;
      const keys = Object.keys(result.data.assets);
      keys.forEach((assetId) => {
        if (result.data.assets[assetId].contract) {
          if (result.data.assets[assetId].contracts) {
            throw new Error('Incorrect API data!');
          }

          result.data.assets[assetId].contracts = [result.data.assets[assetId].contract];
        }
      });

      return result;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  public getUserAddressesAsync = async (address: string): Promise<User> => {
    try {
      const response = await axios.get(USER_ADDRESSES + address);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  public getWalletBalanceAsync = async (address: string): Promise<WalletBalance> => {
    try {
      const response = await axios.get(WALLET_BALANCE + address);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  public getUserAppsAsync = async (address: string, gameName: string): Promise<UserGames> => {
    try {
      const url = USER_GAMES.replace('{address}', address ? `/${address}` : '') + gameName;
      const response = await axios.get(url);
      return response.data;
    } catch (e) {
      console.warn(e);
      return {} as UserGames;
    }
  };

  public getCarouselsAsync = async (): Promise<CarouselItem[]> => {
    try {
      const response = await axios.get(CAROUSELS);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  // public postWithdrawVerify = async (
  //   address: string,
  //   asset: string,
  //   blockchain: string,
  //   signerAddress: string,
  //   quantity: number,
  //   source: CancelTokenSource
  // ): Promise<IWithdrawVerificationResponse> => {
  //   console.log('address', address);
  //   console.log('asset', asset);
  //   console.log('blockchain', blockchain);
  //   console.log('signerAddress', signerAddress);
  //   console.log('quantity', quantity);
  //   console.log('source', source);
  //   return axios
  //     .get('/fake-verify-withdraw-responses/error-pooladdress-doenst-own-card.json')
  //     .then((response) => {
  //       // console.log('response', response);
  //       return Promise.resolve(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //       return Promise.reject(error);
  //     });
  // };

  // CancelToken = axios.CancelToken;
  //
  // sourceTest: CancelTokenSource = this.CancelToken.source();
  //
  // CancelToken = axios.CancelToken;
  //
  // cancel: any;
  //
  // public testCancel = (source: CancelTokenSource) => {
  //   console.log('source 2', source);
  //   source.cancel('Operation canceled by the user.');
  //   // this.cancel();
  // };

  public postWithdrawVerify = async (
    address: string,
    asset: string,
    blockchain: string,
    signerAddress: string,
    quantity: number,
    source: CancelTokenSource
  ): Promise<IWithdrawVerificationResponse> => {
    return axios
      .post(
        WITHDRAW_VERIFY_URL,
        {
          address,
          asset,
          blockchain,
          signerAddress,
          quantity,
        },
        {
          cancelToken: source.token,
          // cancelToken: this.sourceTest.token,
          // cancelToken: new CancelToken(function executor(c) {
          //   // An executor function receives a cancel function as a parameter
          //   const cancel = c;
          // }),
        }
      )
      .then((response) => {
        // console.log('response', response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error('error', error);
        return Promise.reject(error);
      });
  };

  // public postWithdraw = async (
  //   address: string,
  //   asset: string,
  //   blockchain: string,
  //   signature: string,
  //   signerAddress: string,
  //   toAddress: string,
  //   quantity: number
  // ): Promise<IWithdrawResponse> => {
  //   console.log('address', address);
  //   console.log('asset', asset);
  //   console.log('blockchain', blockchain);
  //   console.log('signature', signature);
  //   console.log('signerAddress', signerAddress);
  //   console.log('toAddress', toAddress);
  //   console.log('quantity', quantity);
  //   return axios
  //     .get(
  //       '/fake-withdraw-responses/counterparty-error-failuretrying-to-get-property-status-of-non-object.json'
  //     )
  //     .then((response) => {
  //       // console.log('response', response);
  //       return Promise.resolve(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //       return Promise.reject(error);
  //     });
  // };

  public postWithdraw = async (
    address: string,
    asset: string,
    blockchain: string,
    signature: string,
    signerAddress: string,
    toAddress: string,
    quantity: number,
    source: CancelTokenSource
  ): Promise<IWithdrawResponse> => {
    return axios
      .post(
        WITHDRAW_URL,
        {
          address,
          asset,
          blockchain,
          signature,
          signerAddress,
          toAddress,
          quantity,
        },
        {
          cancelToken: source.token,
          // cancelToken: this.sourceTest.token,
          // cancelToken: new CancelToken(function executor(c) {
          //   // An executor function receives a cancel function as a parameter
          //   const cancel = c;
          // }),
        }
      )
      .then((response) => {
        // console.log('response', response);
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error('error', error);
        return Promise.reject(error);
      });
  };

  public getDepositAddresses = async (
    counterpartyAddress: string,
    source: CancelTokenSource
  ): Promise<IDepositExecutorResponse> => {
    return axios
      .get(
        `https://toblerone.everdreamsoft.com/bearwhale/api/firstOasis/depositExecutor/user/${counterpartyAddress}`,
        {
          cancelToken: source.token,
          // cancelToken: this.sourceTest.token,
          // cancelToken: new CancelToken(function executor(c) {
          //   // An executor function receives a cancel function as a parameter
          //   const cancel = c;
          // }),
        }
      )
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        console.error('error', error);
        return Promise.reject(error);
      });
  };

  // public getDepositAddresses = async (
  //   counterpartyAddress: string,
  //   source: CancelTokenSource
  // ): Promise<IDepositExecutorResponse> => {
  //   console.log('counterpartyAddress', counterpartyAddress);
  //   console.log('source', source);
  //   return axios
  //     .get('/fake-deposit-responses/error-daniela.json')
  //     .then((response) => {
  //       return Promise.resolve(response.data);
  //     })
  //     .catch((error) => {
  //       console.error('error', error);
  //       return Promise.reject(error);
  //     });
  // };
}
