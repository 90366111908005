import React, { useState } from 'react';
import { Button, Collapse, Modal } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { User } from '../../models/user-model';
import ShortTextComponent from '../helpers/short-text/short-text-component';
import './blockchain-address-component.scss';

function BlockchainAddressComponent(props: { user: User }): JSX.Element {
  const { user } = props;
  const [userAddress, setUserAddress] = useQueryState('address', '');
  const [addressesCollapsed, setAddressesCollapsed] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalText, setModalText] = useState<string>();

  const copyAddressToClipboard = () => () => {
    navigator.clipboard
      .writeText(userAddress)
      .then(() => {
        setModalText('was copied to your clipboard!');
        setShowModal(true);
      })
      .catch(() => {
        setModalText('cannot be copied to your clipboard!');
        setShowModal(true);
      });
  };

  const handleModalClose = () => setShowModal(false);

  return (
    <div id="blockchain-address-block">
      <div>
        <div id="qr-code" className="mb-3">
          <QRCode
            size={128}
            level="Q"
            value={userAddress}
            className="qr-code"
            onClick={copyAddressToClipboard()}
          />
          <Modal id="qr-code-modal" show={showModal} onHide={handleModalClose}>
            <Modal.Header>
              <Modal.Title>Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {userAddress} <br /> {modalText}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleModalClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <h6 className="mb-0">Blockchain Address</h6>
        <span className="d-block text-muted mainBrowsing">
          <ShortTextComponent text={userAddress} numberOfChars={12} />
        </span>
      </div>
      {user.data?.addresses && (
        <div className="card-header bg-transparent header-elements-inline">
          <span>Casa Addresses</span>
          <div className="header-elements">
            <div className="list-icons">
              <div
                className="cursor-pointer text-secondary"
                onClick={() => setAddressesCollapsed(!addressesCollapsed)}
                onKeyDown={() => setAddressesCollapsed(!addressesCollapsed)}
                role="button"
                tabIndex={-1}
              >
                <i
                  className={`${
                    addressesCollapsed ? 'icon-chevron-up' : 'icon-chevron-down'
                  } collections-toggle-image`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Collapse in={!addressesCollapsed}>
        <div>
          <ul className="nav nav-sidebar flex-column">
            {user.data?.addresses?.map((a) => (
              <li className="nav-item" key={a.address}>
                <Link
                  to={{
                    pathname: '/address',
                    search: `?address=${a.address}`,
                  }}
                  onClick={() => setUserAddress(a.address)}
                  className="nav-link walletLink active"
                >
                  <div id="blockchain-image-container">
                    <div id={`${a.blockchain}`} className="blockchain-image" />
                    <ShortTextComponent text={a.address} numberOfChars={12} />
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </Collapse>
    </div>
  );
}

export default BlockchainAddressComponent;
