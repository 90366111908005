import React, { useState } from 'react';
import ReactGA from 'react-ga';
import './main-menu-component.scss';
import { Button, FormControl, InputGroup, Nav, Navbar } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import orbLogo from '../../assets/images/logo-orb-explorer.png';
import WalletMenu from '../../controls/wallet-menu/wallet-menu-component';

export default function MainMenuComponent(): JSX.Element {
  const location = useLocation();

  const [address, setAddress] = useState('');

  React.useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOGLE_ANALYTICS_KEY || '');
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  const handleSearchKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (event.key === 'Enter') {
      event.preventDefault();
      window.location.replace(`/address?address=${address}`);
    }
  };

  const handleClick = () => {
    if (address !== undefined) {
      window.location.replace(`/address?address=${address}`);
    }
  };

  return (
    <Navbar id="header" expand="md">
      <div id="logo-container">
        <Navbar.Brand href="/" id="logo">
          <img src={orbLogo} loading="lazy" alt="" />
        </Navbar.Brand>
      </div>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="main-navigation">
        <InputGroup id="search-field">
          <FormControl
            onChange={(event) => setAddress(event.target.value)}
            onKeyPress={(event) => handleSearchKeyPress(event)}
            placeholder="Search address"
          />
          <Button onClick={handleClick} variant="outline-secondary">
            <i className="icon-search4" />
          </Button>
        </InputGroup>
        <Nav navbarScroll className="ml-auto align-items-center" id="header-navbar">
          <Link
            to="/collections"
            state={{ preSelectedTab: 'collections' }}
            className="navbar-nav-link"
          >
            Collections
          </Link>
          <Link
            to="/collections"
            state={{ preSelectedTab: 'transactions' }}
            className="navbar-nav-link"
          >
            Transactions
          </Link>
          <WalletMenu />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
