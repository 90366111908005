import React from 'react';
import { Button, CloseButton, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Wallet from '../../models/wallet-model';
import WalletService from '../../services/wallet/wallet-service';
import Tools from '../../tools';
import './wallet-drop-down-control.scss';

const isInsideCasaTookan = !!window.webXCP;

async function connectWallet(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  service: WalletService
): Promise<void> {
  e.preventDefault();
  await service.connect();
}

async function disconnectWallet(
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  service: WalletService
): Promise<void> {
  e.preventDefault();
  await service.disconnect();
}

export default function WalletDropDown(walletService: WalletService, wallet: Wallet): JSX.Element {
  const { isInstalled, address, chain } = wallet;
  const service = walletService;
  const key = service.getName();

  if (wallet && isInstalled) {
    return (
      <Dropdown.Item
        key={key}
        as={Link}
        to={
          address
            ? { pathname: '/address', search: `?address=${address}` }
            : { pathname: '/collections' }
        }
      >
        <div className="d-flex flex-column text-body mt-2">
          <div>
            <i className={`d-inline px-3 py-2 blockchain-img-${Tools.getNetworkName(chain)}`}>{}</i>
            <span className="d-inline text-capitalize font-weight-bold">
              {Tools.getNetworkLongName(chain)}
            </span>
            {!isInsideCasaTookan && address && (
              <CloseButton
                className="btn-sm float-end p-0"
                onClick={(e) => disconnectWallet(e, service)}
              />
            )}
          </div>
          <div>
            {address ? (
              <div className="float-end">
                {!isInsideCasaTookan && (
                  <span className={`d-inline px-2 mx-2 wallet-img-${service.getName()}`}>{}</span>
                )}
                <span className="d-inline">{Tools.shortenText(address || '', 12)}</span>
              </div>
            ) : (
              <div className="float-end">
                <Button
                  onClick={(e) => connectWallet(e, service)}
                  className="btn-sm connect-button float-end"
                >
                  <span>Connect with {key}</span>{' '}
                  {!isInsideCasaTookan && (
                    <span className={`d-inline px-2 wallet-img-${service.getName()}`}>
                      &nbsp;{}
                    </span>
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Dropdown.Item>
    );
  }

  return (
    <Dropdown.Item
      key={key}
      as={Link}
      target="_blank"
      to={{
        pathname: service.getDownloadWalletPath(),
      }}
    >
      <div className="d-flex flex-column text-body mt-2">
        <div>
          <span className={`d-inline px-3 py-2 blockchain-img-${Tools.getNetworkName(chain)}`}>
            {}
          </span>
          <span className="d-inline text-capitalize font-weight-bold">
            {Tools.getNetworkName(chain)}
          </span>
        </div>
        <div>
          <span className="float-end font-weight-bold">Wallet is not installed</span>
        </div>
      </div>
    </Dropdown.Item>
  );
}
