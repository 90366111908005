import React, { useState } from 'react';
import './collection-image-component.scss';

import 'lightgallery/scss/lightgallery.scss';
import LightGallery from 'lightgallery/react';
import { Link } from 'react-router-dom';
import { Collection } from '../../models/collection-model';

export default function CollectionImage(params: { collectionItem: Collection }): JSX.Element {
  const collectionParam = params;

  const [collection] = useState<Collection>(collectionParam.collectionItem);

  return (
    <div className="collection-list-item col-12 col-sm-6 col-md-4 col-xl-3 p-2">
      <div className="card">
        <div className="card-image">
          <LightGallery
            licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
            startAnimationDuration={0}
            speed={0}
            toggleThumb={false}
            backdropDuration={200}
            slideEndAnimation
            controls={false}
            counter={false}
            download={false}
          >
            <a href={collection.image} data-src={collection.image}>
              <img
                src={collection.image}
                className="card-img imageThumb lazy"
                loading="lazy"
                alt={collection.name}
              />
            </a>
          </LightGallery>
        </div>
        <div className="card-body">
          <div className="title-line">
            <h3>{collection.name}</h3>
          </div>
          <p className="collection-description">
            {collection.description.length >= 103
              ? `${collection.description.substring(0, 100)}...`
              : collection.description}
          </p>
          <Link className="button" to={`/collection?collection=${collection.id}`}>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
}
