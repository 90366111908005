import React, { useEffect, useState } from 'react';
import './collection-view-component.scss';
import 'lightgallery/scss/lightgallery.scss';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import { Link } from 'react-router-dom';
import Button from '@restart/ui/esm/Button';
import axios, { CancelTokenSource } from 'axios';
import { BalanceCollection } from '../../models/balance-collection-model';
import { WalletOrb } from '../../models/wallet-orb-model';
import ApiService from '../../services/api-service';
import DepositModal1 from '../../modals/deposit-modal-1/deposit-modal-1-component';
import DepositModal2 from '../../modals/deposit-modal-2/deposit-modal-2-component';
import WithdrawModal1 from '../../modals/withdraw-modal-1/withdraw-modal-1-component';
import WithdrawModal2 from '../../modals/withdraw-modal-2/withdraw-modal-2-component';
import WithdrawModal3 from '../../modals/withdraw-modal-3/withdraw-modal-3-component';
import WithdrawModal4 from '../../modals/withdraw-modal-4/withdraw-modal-4-component';
import WithdrawModal5 from '../../modals/withdraw-modal-5/withdraw-modal-5-component';
import IWithdrawVerificationResponse from '../../models/withdraw-verification-response';
import IUserAddresses from '../../models/user-addresses-model';
import { IDepositExecutorResponse } from '../../models/deposit-executor-response';
import IWithdrawResponse from '../../models/withdraw-response';

export default function CollectionViewComponent(params: {
  item: BalanceCollection;
  ownWallet: boolean;
  selectedAddress: string;
  userAddresses: IUserAddresses;
}): JSX.Element {
  type TypeBlockchain = 'Counterparty' | 'Ethereum' | undefined;
  const { item, ownWallet, selectedAddress, userAddresses } = params;
  const api = new ApiService();
  const { CancelToken } = axios;
  const [collectionItem] = useState<BalanceCollection>(item);
  const [depositAddress, setDepositAddress] = useState<string>();
  const [depositBlockchain, setDepositBlockchain] = useState<TypeBlockchain>();
  const [depositInterval, setDepositInterval] = useState<NodeJS.Timeout>();
  const [depositLoading, setDepositLoading] = useState<boolean>(false);
  const [depositModal1Visibility, setDepositModal1Visibility] = useState(false);
  const [depositModal2Visibility, setDepositModal2Visibility] = useState(false);
  const [gasAvailable, setGasAvailable] = useState<number>();
  const [gasMinimumRequired, setGasMinimumRequired] = useState<number>(0);
  const [gasRequired, setGasRequired] = useState<number>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource>(
    CancelToken.source()
  );
  const [userHasEnoughBitCrystals, setUserHasEnoughBitCrystals] = useState<boolean>();
  const [verifyWithdrawError, setVerifyWithdrawError] = useState<boolean>(false);
  const [verifyWithdrawErrorMessage, setVerifyWithdrawErrorMessage] = useState<string>();
  const [withdrawAddress, setWithdrawAddress] = useState<string>();
  const [withdrawModal1Visibility, setWithdrawModal1Visibility] = useState(false);
  const [withdrawModal2Visibility, setWithdrawModal2Visibility] = useState(false);
  const [withdrawModal3Visibility, setWithdrawModal3Visibility] = useState(false);
  const [withdrawModal4Visibility, setWithdrawModal4Visibility] = useState(false);
  const [withdrawModal5Visibility, setWithdrawModal5Visibility] = useState(false);
  const [withdrawBlockchain, setWithdrawBlockchain] = useState<TypeBlockchain>();
  const [withdrawError, setWithdrawError] = useState<boolean>(false);
  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState<string>();
  const [withdrawInProgress, setWithdrawInProgress] = useState<boolean>(false);
  const [withdrawOrb, setWithdrawOrb] = useState<WalletOrb | undefined>(undefined);
  const [withdrawVerified, setWithdrawVerified] = useState<boolean>(false);
  const [withdrawTxHash, setWithdrawTxHash] = useState<string>();

  function cancelHttpRequest() {
    cancelTokenSource.cancel('Operation canceled by the user.');
    setCancelTokenSource(CancelToken.source());
  }

  function clearDepositInterval() {
    if (depositInterval) {
      clearInterval(depositInterval);
    }
  }

  const getDepositAddresses = async (blockchain: string): Promise<IDepositExecutorResponse> => {
    return api
      .getDepositAddresses(userAddresses.counterparty, cancelTokenSource)
      .then((response: IDepositExecutorResponse) => {
        console.log('getDepositAddresses response', response);
        setDepositLoading(false);
        response.data.user.deposit.forEach((address) => {
          if (address.blockchain === blockchain) {
            setDepositAddress(address.address);
          }
        });
        return Promise.resolve(response);
      })
      .catch((error) => {
        setDepositLoading(false);
        console.error('error', error);
        return Promise.reject(error);
      });
  };

  async function verifyWithdraw(blockchain: 'Counterparty' | 'Ethereum'): Promise<void> {
    if (withdrawOrb) {
      await api
        .postWithdrawVerify(
          selectedAddress,
          withdrawOrb.asset.id,
          blockchain.toLocaleLowerCase(),
          userAddresses.ethereum,
          1,
          cancelTokenSource
        )
        .then((response: IWithdrawVerificationResponse) => {
          console.log('verifyWithdraw response', response);
          if (response.data.query.balance !== 'pass') {
            if (!response.data.query.balancePay && response.data.query.balancePool) {
              setGasAvailable(response.data.gas.adapted.available);
              setGasRequired(response.data.gas.adapted.required);
            }
            if (!response.data.query.balanceSender) {
              setVerifyWithdrawError(true);
              setVerifyWithdrawErrorMessage(
                'You do not own this Orb, therefore you cannot withdraw it, sorry.'
              );
            }
            if (!response.data.query.balancePool) {
              setVerifyWithdrawError(true);
              setVerifyWithdrawErrorMessage(
                'This Orb is temporarily out of stock. Please, try later. We apologize for the inconvenience.'
              );
            }
          } else if (response.data.gas) {
            setGasAvailable(response.data.gas.adapted.available);
            setGasRequired(response.data.gas.adapted.required);
          }
          setWithdrawVerified(true);
        })
        .catch((error) => {
          console.error('error', error);
          if (error.message !== 'Operation canceled by the user.') {
            setVerifyWithdrawError(true);
            setVerifyWithdrawErrorMessage(
              'The server encountered an unexpected error, sorry. Please, contact our support.'
            );
            setWithdrawVerified(true);
          }
        });
    }
  }

  const closeWithdrawModal1 = () => {
    setWithdrawModal1Visibility(false);
  };

  const closeWithdrawModal2 = () => {
    cancelHttpRequest();
    setWithdrawBlockchain(undefined);
    setWithdrawAddress(undefined);
    setWithdrawVerified(false);
    setVerifyWithdrawError(false);
    setWithdrawModal2Visibility(false);
  };

  const closeWithdrawModal3 = () => {
    setWithdrawVerified(false);
    setWithdrawModal3Visibility(false);
  };

  const closeWithdrawModal4 = () => {
    cancelHttpRequest();
    setWithdrawVerified(false);
    setWithdrawInProgress(false);
    setWithdrawModal4Visibility(false);
  };

  const closeWithdrawModal5 = () => {
    setWithdrawVerified(false);
    setWithdrawModal5Visibility(false);
  };

  const closeDepositModal1 = () => {
    clearDepositInterval();
    cancelHttpRequest();
    setWithdrawVerified(false);
    setDepositModal1Visibility(false);
  };

  const closeDepositModal2 = () => {
    clearDepositInterval();
    cancelHttpRequest();
    setWithdrawVerified(false);
    setDepositModal2Visibility(false);
  };

  const returnToWithdrawModal1 = () => {
    cancelHttpRequest();
    setWithdrawBlockchain(undefined);
    setWithdrawAddress(undefined);
    setWithdrawVerified(false);
    setVerifyWithdrawError(false);
    setWithdrawModal1Visibility(true);
    setWithdrawModal2Visibility(false);
  };

  const returnToWithdrawModal2 = () => {
    clearDepositInterval();
    cancelHttpRequest();
    setDepositModal1Visibility(false);
    setWithdrawModal2Visibility(true);
    setWithdrawModal3Visibility(false);
  };

  const returnToWithdrawModal3 = () => {
    cancelHttpRequest();
    setWithdrawModal3Visibility(true);
    setWithdrawModal4Visibility(false);
  };

  const openWithdrawModal3 = () => {
    setWithdrawModal3Visibility(true);
    setWithdrawModal2Visibility(false);
  };

  const openWithdrawModal4 = () => {
    setWithdrawModal4Visibility(true);
    setWithdrawModal3Visibility(false);
  };

  const openWithdrawModal5 = () => {
    setWithdrawModal5Visibility(true);
    setWithdrawModal4Visibility(false);
  };

  const openDepositModal1 = () => {
    setDepositModal1Visibility(true);
    setWithdrawModal2Visibility(false);
  };

  const openDepositModal2 = (blockchain: 'Counterparty' | 'Ethereum') => {
    setDepositBlockchain(blockchain);
    setDepositLoading(true);
    getDepositAddresses(blockchain.toLocaleLowerCase()).then(() => {
      setDepositModal2Visibility(true);
      setDepositModal1Visibility(false);
    });
    const interval = setInterval(() => {
      setDepositLoading(true);
      getDepositAddresses(blockchain.toLocaleLowerCase());
    }, 5000);
    setDepositInterval(interval);
    return () => {
      clearDepositInterval();
      setDepositLoading(false);
    };
  };

  function checkIfUserHasEnoughBitCrystals(): boolean {
    if (gasAvailable && gasRequired) {
      if (gasAvailable >= gasRequired) {
        return true;
      }
    }
    return false;
  }

  const withdraw = async (signedMessage: string) => {
    setWithdrawInProgress(true);
    if (withdrawAddress && withdrawBlockchain && withdrawOrb) {
      await api
        .postWithdraw(
          withdrawAddress,
          withdrawOrb.asset.id,
          withdrawBlockchain.toLocaleLowerCase(),
          signedMessage,
          userAddresses.ethereum,
          withdrawAddress,
          1,
          cancelTokenSource
        )
        .then((response: IWithdrawResponse) => {
          console.log('withdraw response', response);
          setWithdrawInProgress(false);
          if (response.data.message === 'success') {
            setWithdrawTxHash(response.data.transactions.receive.tx[0].txHash);
            openWithdrawModal5();
          } else {
            setWithdrawError(true);
            setWithdrawErrorMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const selectWithdrawBlockchain = async (blockchain: 'Counterparty' | 'Ethereum') => {
    setWithdrawBlockchain(blockchain);
    if (blockchain === 'Counterparty') {
      setWithdrawAddress(userAddresses.counterparty);
    } else if (blockchain === 'Ethereum') {
      setWithdrawAddress(userAddresses.ethereum);
    }
    setWithdrawModal2Visibility(true);
    setWithdrawModal1Visibility(false);
    await verifyWithdraw(blockchain);
  };

  useEffect(() => {
    if (gasAvailable !== undefined && gasRequired !== undefined) {
      setUserHasEnoughBitCrystals(checkIfUserHasEnoughBitCrystals());
      setGasMinimumRequired(gasRequired - gasAvailable);
    }
  }, [gasAvailable, gasRequired]);

  return (
    <div className="mb-2 pl-2">
      <div className="row mw-200">
        <div className="card w-100">
          <div className="media flex-column flex-sm-row">
            <div className="mr-sm-3 mb-sm-0">
              <div className="card-img-actions">
                <LightGallery
                  licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
                  elementClassNames="small-gallery-img"
                  thumbHeight="5rem"
                  startAnimationDuration={0}
                  speed={0}
                  toggleThumb={false}
                  backdropDuration={200}
                  slideEndAnimation
                  controls={false}
                  counter={false}
                  download={false}
                >
                  <a href={collectionItem.imageUrl} data-src={collectionItem.imageUrl}>
                    <img
                      src={collectionItem.imageUrl}
                      className="card-img"
                      loading="lazy"
                      alt={collectionItem.name}
                    />
                  </a>
                </LightGallery>
              </div>
            </div>
            <div className="media-body">
              <h6 className="media-title collectionName">{collectionItem.name}</h6>
              <ul className="list-inline list-inline-dotted text-muted mb-2">
                <Link to={`/collection?collection=${collectionItem.id}`}>
                  <i className="icon-book-play mr-2" /> View collection
                </Link>
              </ul>
              <span className="collectionDescription">{collectionItem.description}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row flex-row">
        {collectionItem.orbs?.slice(0, isExpanded ? Number.MAX_SAFE_INTEGER : 6).map((x) => (
          <div
            className="pt-2 col-sm-4 col-xl-2 col-6 d-flex flex-column justify-content-between"
            key={x.asset.id + (x.token.tokenId ? `_${x.token.tokenId}` : '')}
          >
            <LightGallery
              licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
              elementClassNames="cursor-pointer flex-grow-1"
              startAnimationDuration={0}
              speed={0}
              toggleThumb={false}
              backdropDuration={200}
              slideEndAnimation
              controls={false}
              counter={false}
              download={false}
              plugins={[lgThumbnail]}
            >
              <img
                src={x.asset.image ?? collectionItem.imageUrl}
                className="card-img h-100"
                loading="lazy"
                alt={x.asset.name}
              />
            </LightGallery>
            <div className="card-footer">
              <div className="d-flex flex-column">
                <div className="align-items-center d-flex justify-content-around font-weight-semibold">
                  <div>Quantity:</div>
                  <div className="font-weight-bold assetBalanceStyle assetBalance">
                    {x.adaptedQuantity ? x.adaptedQuantity : x.quantity}
                    <span className={`blockchain-img-${x.chain}`} />
                  </div>
                </div>
                {collectionItem.id === 'eSog' &&
                  ownWallet &&
                  selectedAddress === userAddresses.firstOasisKlaytn && (
                    <div className="d-flex  flex-nowrap">
                      <Button
                        className="btn btn-light"
                        onClick={() => {
                          setWithdrawModal1Visibility(!withdrawModal1Visibility);
                          setWithdrawOrb(x);
                        }}
                      >
                        Withdraw
                        <i className="icon-play3 ml-2" />
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))}
        {collectionItem.orbs?.length > 6 ? (
          <Button
            className="btn btn-secondary btn-lg btn-block ml-2 mr-2"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? 'Hide' : 'View all'}
          </Button>
        ) : (
          ''
        )}
      </div>
      <WithdrawModal1
        closeWithdrawModal1={closeWithdrawModal1}
        orb={withdrawOrb}
        selectWithdrawBlockchain={selectWithdrawBlockchain}
        withdrawModal1Visibility={withdrawModal1Visibility}
      />
      <WithdrawModal2
        closeWithdrawModal2={closeWithdrawModal2}
        gasAvailable={gasAvailable}
        gasMinimumRequired={gasMinimumRequired}
        gasRequired={gasRequired}
        openDepositModal1={openDepositModal1}
        openWithdrawModal3={openWithdrawModal3}
        orb={withdrawOrb}
        returnToWithdrawModal1={returnToWithdrawModal1}
        userHasEnoughBitCrystals={userHasEnoughBitCrystals}
        verifyWithdrawError={verifyWithdrawError}
        verifyWithdrawErrorMessage={verifyWithdrawErrorMessage}
        withdrawBlockchain={withdrawBlockchain}
        withdrawModal2Visibility={withdrawModal2Visibility}
        withdrawVerified={withdrawVerified}
      />
      <WithdrawModal3
        closeWithdrawModal3={closeWithdrawModal3}
        openWithdrawModal4={openWithdrawModal4}
        orb={withdrawOrb}
        returnToWithdrawModal2={returnToWithdrawModal2}
        withdrawAddress={withdrawAddress}
        withdrawBlockchain={withdrawBlockchain}
        withdrawModal3Visibility={withdrawModal3Visibility}
      />
      <WithdrawModal4
        closeWithdrawModal4={closeWithdrawModal4}
        gasRequired={gasRequired}
        orb={withdrawOrb}
        returnToWithdrawModal3={returnToWithdrawModal3}
        userAddresses={userAddresses}
        withdraw={withdraw}
        withdrawAddress={withdrawAddress}
        withdrawBlockchain={withdrawBlockchain}
        withdrawError={withdrawError}
        withdrawErrorMessage={withdrawErrorMessage}
        withdrawInProgress={withdrawInProgress}
        withdrawModal4Visibility={withdrawModal4Visibility}
        withdrawOrb={withdrawOrb}
      />
      <WithdrawModal5
        closeWithdrawModal5={closeWithdrawModal5}
        orb={withdrawOrb}
        withdrawBlockchain={withdrawBlockchain}
        withdrawModal5Visibility={withdrawModal5Visibility}
        withdrawTxHash={withdrawTxHash}
      />
      <DepositModal1
        closeDepositModal1={closeDepositModal1}
        depositModal1Visibility={depositModal1Visibility}
        depositLoading={depositLoading}
        gasMinimumRequired={gasMinimumRequired}
        openDepositModal2={openDepositModal2}
        returnToWithdrawModal2={returnToWithdrawModal2}
      />
      <DepositModal2
        closeDepositModal2={closeDepositModal2}
        depositAddress={depositAddress}
        depositBlockchain={depositBlockchain}
        depositModal2Visibility={depositModal2Visibility}
        gasMinimumRequired={gasMinimumRequired}
      />
    </div>
  );
}
