import React, { useEffect, useState } from 'react';
import { Collapse, Tab, Tabs } from 'react-bootstrap';
import { Location, useLocation } from 'react-router-dom';
import { useQueryState } from 'react-router-use-location-state';
import { ClipLoader } from 'react-spinners';
import hash from 'object-hash';
import { BalanceCollection } from '../../models/balance-collection-model';
import { Transactions } from '../../models/transactions-model';
import ApiService from '../../services/api-service';
import BlockchainAddressComponent from '../blockchain-address/blockchain-address-component';
import CollectionViewComponent from '../collection-view/collection-view-component';
import CollectionsTitlesComponent from '../collections-titles/collections-titles-component';
import NoCollectiblesComponent from '../no-collectibles/no-collectibles-components';
import TransactionDetails from '../transaction-details/transaction-details-component';
import './wallet-component.scss';
import { User } from '../../models/user-model';
import IUserAddresses from '../../models/user-addresses-model';

export default function WalletComponent(): JSX.Element {
  const api = new ApiService();
  const location: Location = useLocation();
  const [balance, setBalance] = useState<BalanceCollection[]>();
  const [collection] = useQueryState('collection', '');
  const [ownWallet, setOwnWallet] = useState<boolean>(false);
  const [selectedAddress] = useQueryState('address', '');
  const [sidebarVisible] = useState(true);
  const [transactions, setTransactions] = useState<Transactions>();
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [user, setUser] = useState<User>({} as User);
  const [userAddresses, setUserAddresses] = useState<IUserAddresses>({
    counterparty: '',
    ethereum: '',
    firstOasisKlaytn: '',
  });
  const [walletEthereumAddress, setWalletEthereumAddress] = useState<string>();

  function fetchData(): void {
    api.getWalletBalanceAsync(selectedAddress).then((res) => {
      const balanceCollection = collection
        ? res?.data?.collections.filter((x) => x.id === collection)
        : res?.data?.collections;
      setBalance(balanceCollection);
    });

    api.getTransactionsAsync('', selectedAddress).then((res) => {
      setTransactions(res);
      setTransactionsLoading(false);
    });
  }

  function fetchUserAddresses(): void {
    api.getUserAddressesAsync(selectedAddress).then((userResponse: User) => {
      setUser(userResponse);
      const userResponseAddresses = { counterparty: '', ethereum: '', firstOasisKlaytn: '' };
      userResponse.data.addresses.forEach((address) => {
        if (address.blockchain === 'counterparty') {
          userResponseAddresses.counterparty = address.address;
        } else if (address.blockchain === 'ethereum') {
          userResponseAddresses.ethereum = address.address;
        } else if (address.blockchain === 'firstOasisKlaytn') {
          userResponseAddresses.firstOasisKlaytn = address.address;
        }
      });
      setUserAddresses(userResponseAddresses);
    });
  }

  async function getEthereumAddress() {
    const { ethereum } = window;
    const metaMaskAccounts = await ethereum.request({
      method: 'eth_accounts',
    });
    console.log('metaMaskAccounts', metaMaskAccounts);
    setWalletEthereumAddress(metaMaskAccounts[0]);
  }

  useEffect(() => {
    fetchData();
    fetchUserAddresses();
    getEthereumAddress();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedAddress, collection]);

  useEffect(() => {
    if (userAddresses.ethereum && walletEthereumAddress) {
      if (userAddresses.ethereum === walletEthereumAddress) {
        setOwnWallet(true);
      }
    }
  }, [userAddresses, walletEthereumAddress]);

  return (
    <div id="page-wallet">
      <h1>Wallet</h1>
      <div>
        <Collapse in={sidebarVisible} dimension="width">
          <div id="wallet-block">
            <div className="mb-2">
              <BlockchainAddressComponent user={user} />
            </div>
            {balance ? (
              <CollectionsTitlesComponent
                location={location}
                items={balance?.map((x) => Object.assign(x, { quantity: x?.orbs?.length }))}
                showAllButton
              />
            ) : (
              ''
            )}
          </div>
        </Collapse>
        <div className="tab-content w-100 order-2 order-md-2">
          <div className="nav-tabs-responsive mb-3">
            <Tabs className="mb-3">
              <Tab
                eventKey="collections"
                title={
                  <span className="cursor-pointer">
                    <i className="icon-balance" />
                    Balance
                  </span>
                }
              >
                <div id="wallet-collection">
                  {balance ? (
                    balance.map((x) => (
                      <CollectionViewComponent
                        item={x}
                        key={hash(x)}
                        ownWallet={ownWallet}
                        selectedAddress={selectedAddress}
                        userAddresses={userAddresses}
                      />
                    ))
                  ) : (
                    <NoCollectiblesComponent />
                  )}
                </div>
              </Tab>
              <Tab
                eventKey="transactions"
                title={
                  <span className="cursor-pointer">
                    <i className="icon-loop" />
                    Transactions
                  </span>
                }
              >
                <div>
                  {transactionsLoading ? (
                    <ClipLoader loading={transactionsLoading} />
                  ) : (
                    transactions?.data?.length && (
                      <div>
                        <TransactionDetails
                          transactions={transactions}
                          key={hash(transactions?.data)}
                        />
                      </div>
                    )
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
