/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
export default abstract class BaseWalletService {
  newChainHandler?: (chainId: number) => void;

  newNetworkHandler?: (networkId: number) => void;

  newAccountHandler?: (newAccounts: string) => void;

  disconnectHandler?: () => void;

  async init(
    newChainHandler?: (chainId: number) => void,
    newNetworkHandler?: (networkId: number) => void,
    newAccountHandler?: (newAccounts: string) => void,
    disconnectHandler?: () => void
  ): Promise<void> {
    this.newChainHandler = newChainHandler;
    this.newNetworkHandler = newNetworkHandler;
    this.newAccountHandler = newAccountHandler;
    this.disconnectHandler = disconnectHandler;
  }

  disconnect = async (): Promise<void> => {
    this.handleDisconnect();
  };

  public getDownloadWalletPath = (): string => {
    return '';
  };

  protected handleNewAccounts = (newAccounts: any) => {
    this.logInfo(newAccounts);
    if (this.newAccountHandler) {
      this.newAccountHandler(newAccounts);
    }
  };

  protected handleNewChain = (chainId: any) => {
    this.logInfo(chainId);

    if (this.newChainHandler) {
      if (chainId.toString().startsWith('0x')) {
        const chain: number = parseInt(chainId, 16);
        this.newChainHandler(chain);
      } else if (Number.isFinite(chainId)) {
        this.newChainHandler(chainId);
      }
    }
  };

  protected handleNewNetwork = (networkId: any) => {
    this.logInfo(networkId);
    if (this.newNetworkHandler) {
      this.newNetworkHandler(networkId);
    }
  };

  protected handleDisconnect = () => {
    if (this.disconnectHandler) {
      this.disconnectHandler();
    }
  };

  extractAccount = (accounts: string | string[]): string => {
    return Array.isArray(accounts) ? accounts[0] : accounts;
  };

  logError = (err: any) => {
    console.error(err);
  };

  logInfo = (message: string) => {
    console.warn(message);
  };
}
