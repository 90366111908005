import Web3 from 'web3';
import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

export default class Web3Service extends BaseWalletService implements WalletService {
  init = async (
    newChainHandler?: (chainId: number) => void,
    newNetworkHandler?: (networkId: number) => void,
    newAccountHandler?: (newAccounts: string) => void,
    disconnectHandler?: () => void
  ) => {
    super.init(newChainHandler, newNetworkHandler, newAccountHandler, disconnectHandler);

    if (this.isWalletInstalled()) {
      const account = await this.getAccounts();

      this.handleNewChain(1);
      this.handleNewNetwork(1);

      if (account && newAccountHandler) {
        newAccountHandler(account);
      }
    }
  };

  connect = async (): Promise<void> => {
    await web3.eth.requestAccounts();

    this.handleNewChain(1);
    this.handleNewNetwork(1);
  };

  isWalletInstalled = (): boolean => {
    return !!web3?.eth;
  };

  getAccounts = async (): Promise<string> => {
    const accounts = await web3.eth.getAccounts();

    this.handleNewChain(1);
    this.handleNewNetwork(1);

    return accounts[0];
  };

  getName = (): string => {
    return 'Web3';
  };

  personalSign = async (message: string, password?: string): Promise<string> => {
    const account = await this.getAccounts();
    const result = web3.eth.personal.sign(message, account, password || '');
    return result;
  };
}
