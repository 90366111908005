import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Link, Location } from 'react-router-dom';
import { ICollectionItem } from '../../models/collection-item-model';
import './collections-titles-component.scss';
import { BalanceCollection } from '../../models/balance-collection-model';

export default function CollectionsTitlesComponent(params: {
  items: BalanceCollection[];
  location: Location;
  showAllButton: boolean;
}): JSX.Element {
  const { items, location, showAllButton } = params;
  const [collectionsTitles] = useState<ICollectionItem[]>(items);
  const [showAllButton1] = useState<boolean>(showAllButton);
  const [expanded, setExpanded] = useState(true);

  const getCollectionLink = (newCollection?: string): string => {
    const searchParams = new URLSearchParams(location?.search);

    if (newCollection) {
      searchParams.set('collection', newCollection);
    } else {
      searchParams.delete('collection');
    }

    return `${location.pathname}?${searchParams.toString()}`;
  };

  return (
    <div>
      <div className="card-header bg-transparent header-elements-inline">
        <span className="font-size-sm font-weight-semibold">Collections</span>
        <div className="header-elements">
          <div className="list-icons">
            <div
              className="cursor-pointer text-secondary"
              onClick={() => setExpanded(!expanded)}
              onKeyDown={() => setExpanded(!expanded)}
              role="button"
              tabIndex={-1}
            >
              <i
                className={`${
                  expanded ? 'icon-chevron-up' : 'icon-chevron-down'
                } collections-toggle-image`}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <Collapse in={expanded}>
          <ul className="nav nav-sidebar mb-2 flex-column">
            <div>
              {showAllButton1 && (
                <li key="all" className="nav-item hover">
                  <Link to={getCollectionLink(undefined)} className="nav-link d-flex">
                    <div className="p-2">
                      <i className="icon-books mr-2" />
                      <span className="collectionName">All</span>
                    </div>
                    <span className="ml-auto p-2">
                      {collectionsTitles?.reduce(
                        (prev, cur) => prev + (cur.quantity ? cur.quantity : 0),
                        0
                      )}
                    </span>
                  </Link>
                </li>
              )}
              {collectionsTitles?.length ? (
                collectionsTitles.map((m) => (
                  <li id="collectionList-items" key={m.id} className="nav-item hover">
                    <Link to={getCollectionLink(m.id)} className="nav-link pt-0 pb-0 d-flex">
                      <div className="p-2">
                        <i className="icon-books mr-2" />
                        <span className="collectionName">{m.name}</span>
                      </div>
                      <span className="ml-auto p-2">{m.quantity ? m.quantity : ''}</span>
                    </Link>
                  </li>
                ))
              ) : (
                <li id="collectionList-items" className="nav-item hover">
                  <Link to={getCollectionLink(undefined)} className="nav-link d-flex">
                    <div className="p-2">
                      <i className="icon-books mr-2" />
                      <span className="collectionName">No collectibles</span>
                    </div>
                  </Link>
                </li>
              )}
            </div>
          </ul>
        </Collapse>
      </div>
    </div>
  );
}
