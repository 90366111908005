import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import { useQueryState } from 'react-router-use-location-state';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CollectionDetails } from '../../models/collection-details-model';
import { Transactions } from '../../models/transactions-model';
import ApiService from '../../services/api-service';
import TransactionDetails from '../transaction-details/transaction-details-component';
import './collection-details-component.scss';
import Tools from '../../tools';
import { Asset } from '../../models/asset-model';
import LazyCardImageComponent from '../lazy-card-image/lazy-card-image-component';

export default function CollectionDetailsComponent(): JSX.Element {
  const [collectionDetails, setCollectionDetails] = useState<CollectionDetails>();
  const [assets, setAssets] = useState<Asset[]>();
  const [collectionDetailsLoading, setCollectionDetailsLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transactions>();
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [collectionName] = useQueryState('collection', '');
  const [hasMoreData, setHasMoreData] = useState(true);
  const [lastIndex, setLastIndex] = useState(0);

  const fetchMoreData = (): void => {
    const allKeys = Object.keys(collectionDetails?.data.assets || []);

    if (assets && assets.length >= allKeys.length) {
      setHasMoreData(false);
    }

    let newAssets: Asset[] = [];
    const maxIndex = Math.min(lastIndex + 20, allKeys.length);
    for (let i = lastIndex; i < maxIndex; i += 1) {
      const key: string = allKeys[i];
      const item = collectionDetails?.data.assets[key];
      if (item) {
        item.index = i;
        newAssets.push(item);
      }
    }

    newAssets = (assets || []).concat(newAssets);

    setAssets(newAssets);
    setLastIndex(lastIndex + maxIndex);
  };

  useEffect(() => {
    const api = new ApiService();
    api.getCollectionDetailsAsync(collectionName).then((col) => {
      setCollectionDetails(col);
      setCollectionDetailsLoading(false);
    });
    api.getTransactionsAsync(collectionName).then((trx) => {
      setTransactions(trx);
      setTransactionsLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchMoreData();
  }, [collectionDetailsLoading]);

  return (
    <div id="page-collection">
      <h1>Collection</h1>
      <div>
        <div id="collection-description">
          <div
            id="collection-image"
            style={{
              backgroundImage: `url("${collectionDetails?.data?.collection?.imageUrl}")`,
            }}
          />
          <h3>{collectionDetails?.data?.collection?.name}</h3>
          <p>{collectionDetails?.data?.collection?.description}</p>
        </div>
        <div className="tab-content w-100 overflow-hidden">
          <Tabs defaultActiveKey="collections" id="collections-tabs" className="mb-3">
            <Tab
              eventKey="collections"
              title={
                <span className="cursor-pointer">
                  <i className="icon-spinner10 mr-2" />
                  Orbs
                </span>
              }
              key="collections"
            >
              {collectionDetailsLoading ? (
                <ClipLoader loading={collectionDetailsLoading} />
              ) : (
                assets && (
                  <InfiniteScroll
                    className="d-flex flex-wrap tab-pane fade active show"
                    dataLength={assets.length}
                    next={fetchMoreData}
                    hasMore={hasMoreData || true}
                    loader={<div />}
                  >
                    {assets.map((item) => {
                      return (
                        <div
                          className=" col-12 col-sm-6 col-md-4 col-xl-3 p-2"
                          key={`${item.assetId}_${item.index}`}
                        >
                          <div className="card">
                            <LazyCardImageComponent
                              assetParam={item}
                              defaultImagePath={collectionDetails?.data?.collection?.imageUrl || ''}
                            />
                            <div className="card-body">
                              <div className="title-line">
                                <h3>Contract</h3>
                              </div>
                              <p className="collection-description">
                                {Tools.shortenText(item?.contracts?.[0]?.address, 12)}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </InfiniteScroll>
                )
              )}
            </Tab>
            <Tab eventKey="transactions" title="Transactions" key="transactions">
              {transactionsLoading ? (
                <ClipLoader loading={transactionsLoading} />
              ) : (
                transactions?.data?.length && (
                  <div>
                    <TransactionDetails transactions={transactions} />
                  </div>
                )
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}
