import React from 'react';
import { Link } from 'react-router-dom';
import noImg from '../../assets/images/no-image-found.jpg';

export default function NoCollectiblesComponent(): JSX.Element {
  return (
    <div className="row mb-2 pl-2">
      <div className="card card-body w-100">
        <div className="media flex-column flex-sm-row">
          <div className="mr-sm-3 mb-sm-0">
            <div className="card-img-actions">
              <img src={noImg} height="90px" loading="lazy" alt="Missing pic" />
            </div>
          </div>
          <div className="media-body">
            <h6 className="media-title collectionName">No collectibles</h6>
            <ul className="list-inline list-inline-dotted text-muted mb-2">
              <Link to="/collections">
                <i className="icon-book-play mr-2" /> View collections
              </Link>
            </ul>
            <span className="collectionDescription">No collectibles found at this address</span>
          </div>
        </div>
      </div>
    </div>
  );
}
