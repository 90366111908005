import BaseWalletService from './base-wallet-service';
import WalletService from './wallet-service';

// resources
// https://github.com/MetaMask/test-dapp/blob/main/src/index.js

const { ethereum } = window;

export default class MetaMaskService extends BaseWalletService implements WalletService {
  init = async (
    newChainHandler?: (chainId: number) => void,
    newNetworkHandler?: (networkId: number) => void,
    newAccountHandler?: (newAccounts: string) => void,
    disconnectHandler?: () => void
  ) => {
    super.init(newChainHandler, newNetworkHandler, newAccountHandler, disconnectHandler);

    if (this.isWalletInstalled()) {
      ethereum.on('chainChanged', this.handleNewChain);
      ethereum.on('networkChanged', this.handleNewNetwork);
      ethereum.on('accountsChanged', this.handleNewAccounts);

      ethereum.autoRefreshOnNetworkChange = false;
      await this.getNetworkAndChainId();

      try {
        const newAccounts = await ethereum.request({
          method: 'eth_accounts',
        });

        this.handleNewAccounts(newAccounts && newAccounts[0]);
      } catch (err) {
        this.logError(err);
      }
    }
  };

  isWalletInstalled = () => Boolean(ethereum && ethereum.isMetaMask);

  // Connects account (Metamask) to the web page
  connect = async () => {
    try {
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      });

      this.handleNewAccounts(newAccounts && newAccounts[0]);
    } catch (err) {
      this.logError(err);
    }
  };

  // returns account installed on MM
  getAccounts = async (): Promise<string> => {
    try {
      const accounts = await ethereum.request({
        method: 'eth_accounts',
      });

      return accounts[0] || undefined;
    } catch (err) {
      this.logError(err);
      return '';
    }
  };

  // signs a message with password
  personalSign = async (message: string, password?: string): Promise<string> => {
    try {
      const from = await this.getAccounts();
      const msg = `0x${Buffer.from(message, 'utf8').toString('hex')}`;
      const signResult = await ethereum.request({
        method: 'personal_sign',
        params: [msg, from, password],
      });
      return signResult;
    } catch (err) {
      this.logError(err);
      return '';
    }
  };

  getDownloadWalletPath = (): string => {
    return 'https://metamask.io';
  };

  getName = (): string => {
    return 'Metamask';
  };

  private getNetworkAndChainId = async () => {
    try {
      const chainId = await ethereum.request({
        method: 'eth_chainId',
      });

      this.handleNewChain(chainId);

      const networkId = await ethereum.request({
        method: 'net_version',
      });

      this.handleNewNetwork(networkId);
    } catch (err) {
      this.logError(err);
    }
  };
}
