import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Collections from '../../models/collections-model';
import SelectedTabOption from '../../models/selected-tab-option-model';
import { Transactions } from '../../models/transactions-model';
import ApiService from '../../services/api-service';
import CollectionImage from '../collection-image/collection-image-component';
import TransactionDetails from '../transaction-details/transaction-details-component';
import './collections-component.scss';

export default function CollectionsComponent(): JSX.Element {
  const location = useLocation();
  const [collections, setCollections] = useState({} as Collections);
  const [transactions, setTransactions] = useState({} as Transactions);
  const [collectionsLoading, setCollectionsLoading] = useState(true);
  const [transactionsLoading, setTransactionsLoading] = useState(true);
  const [preSelectedTab, setPreSelectedTab] = useState('collections');

  useEffect(() => {
    const api = new ApiService();
    api.getCollectionsAsync().then((collectionsResponse) => {
      setCollections(collectionsResponse);
      setCollectionsLoading(false);
    });
    api.getTransactionsAsync().then((transactionsResponse) => {
      setTransactions(transactionsResponse);
      setTransactionsLoading(false);
    });
  }, []);

  useEffect(() => {
    setPreSelectedTab((location?.state as SelectedTabOption)?.preSelectedTab ?? 'collections');
  }, [location.state]);

  return (
    <div id="page-collections">
      <h1>Collections</h1>
      <div>
        <Tabs
          activeKey={preSelectedTab ?? ''}
          id="collections-tabs"
          className="mb-3"
          onSelect={(s) => setPreSelectedTab(s ?? 'collections')}
        >
          <Tab title="Collections" eventKey="collections">
            {collectionsLoading ? (
              <ClipLoader loading={collectionsLoading} />
            ) : (
              collections?.data?.length && (
                <div className="d-flex flex-wrap tab-pane fade active show" id="allCollections-tab">
                  {collections?.data.map((m) => (
                    <CollectionImage key={m.id} collectionItem={m} />
                  ))}
                </div>
              )
            )}
          </Tab>
          <Tab
            title={
              <span className="cursor-pointer">
                <i className="icon-loop mr-2" />
                Transactions
              </span>
            }
            eventKey="transactions"
          >
            {transactionsLoading ? (
              <ClipLoader loading={transactionsLoading} />
            ) : (
              transactions?.data?.length && (
                <div id="allCollections-tab">
                  <TransactionDetails transactions={transactions} />
                </div>
              )
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
