import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Tools from '../../../tools';

interface IShortTextConfig {
  text: string;
  numberOfChars?: number;
  description?: string;
}

export default function ShortTextComponent(config: IShortTextConfig): JSX.Element {
  const configParam = config;

  const [text, setText] = useState(configParam.text);
  const [numberOfChars, setNumberOfChars] = useState(configParam.numberOfChars);
  const [description, setDescription] = useState(configParam.description);

  useEffect(() => {
    setText(configParam.text);
  }, [configParam.text]);

  useEffect(() => {
    setNumberOfChars(configParam.numberOfChars);
  }, [configParam.numberOfChars]);

  useEffect(() => {
    setDescription(configParam.description);
  }, [configParam.description]);

  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{description || text}</Tooltip>}>
      <span style={{ verticalAlign: 'super' }}>{Tools.shortenText(text, numberOfChars)}</span>
    </OverlayTrigger>
  );
}
