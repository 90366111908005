import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { WalletOrb } from '../../models/wallet-orb-model';
import './withdraw-modal-5-component.scss';

interface IWithdrawModalParams {
  closeWithdrawModal5: MouseEventHandler<HTMLButtonElement>;
  orb: WalletOrb | undefined;
  withdrawBlockchain: string | undefined;
  withdrawModal5Visibility: boolean;
  withdrawTxHash: string | undefined;
}

export default function WithdrawModal5(props: IWithdrawModalParams): JSX.Element {
  const [isOpenState, setOpenState] = useState(false);
  const { closeWithdrawModal5, orb, withdrawBlockchain, withdrawModal5Visibility, withdrawTxHash } =
    props;

  useEffect(() => {
    setOpenState(withdrawModal5Visibility);
  }, [withdrawModal5Visibility]);

  return (
    <Modal
      id="withdraw-modal-5"
      show={isOpenState}
      onHide={closeWithdrawModal5}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Withdrawal on {withdrawBlockchain}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="orb-image">
          <img
            src={orb?.asset.image}
            className="w-100 h-100 p-1 pb-3 d-flex justify-content-center"
            alt="Orb to withdraw"
          />
        </div>
        <h6>Congratulations!</h6>
        <p>Your Orb was withdrawn with success.</p>
        {/* {withdrawTxHash ?? ( */}
        <div id="buttons">
          <Button
            href={
              withdrawBlockchain === 'Ethereum'
                ? `https://etherscan.io/tx/${withdrawTxHash}`
                : `https://xchain.io/tx/${withdrawTxHash}`
            }
            target="_blank"
          >
            See transaction details
          </Button>
        </div>
        {/* )} */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeWithdrawModal5}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
