import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import { CarouselItem } from '../../models/carousel-item-model';
import ApiService from '../../services/api-service';
import './selected-collections-component.scss';

export default function SelectedCollectionsComponent(params: {
  carousel?: CarouselItem[];
}): JSX.Element {
  const initParams = params;

  const [carousel, setCarousel] = useState<CarouselItem[]>(
    initParams.carousel ? initParams.carousel : []
  );

  useEffect(() => {
    const api = new ApiService();
    api.getCarouselsAsync().then((carouselItems) => {
      setCarousel(carouselItems);
    });
  }, []);

  return (
    <div id="selected-collections-component">
      <div className="row">
        <h2 className="font-weight-black">Selected collections</h2>
        {carousel &&
          carousel?.map((x) => {
            const name = x.properties.find((f) => f.name === 'name')?.value || 'Collection name';
            const description =
              x.properties.find((f) => f.name === 'description')?.value || 'Collection description';
            const imagePath =
              x.properties.find((f) => f.name === 'imagePath')?.value ||
              'https://storage.googleapis.com/opensea-static/landing/cheeze-wizard.png';
            const id = x.properties.find((f) => f.name === 'id')?.value || 'eSog';
            return (
              <div
                className="collection-list-item col-12 col-sm-6 col-md-4 col-xl-3 pl-2 pr-2 pb-4"
                key={name}
              >
                <div className="card">
                  <div className="card-image">
                    <LightGallery
                      licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
                      startAnimationDuration={0}
                      speed={0}
                      toggleThumb={false}
                      backdropDuration={200}
                      slideEndAnimation
                      controls={false}
                      counter={false}
                      download={false}
                    >
                      <a href={imagePath} data-src={imagePath}>
                        <img
                          src={imagePath}
                          className="card-img imageThumb lazy"
                          loading="lazy"
                          alt={name}
                        />
                      </a>
                    </LightGallery>
                  </div>
                  <div className="card-body">
                    <div className="title-line">
                      <h3>{name}</h3>
                    </div>
                    <p className="collection-description">
                      {description.length >= 103
                        ? `${description.substring(0, 100)}...`
                        : description}
                    </p>
                    <Link className="button" to={`/collection?collection=${id}`}>
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
