import React, { MouseEventHandler, useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import './deposit-modal-2-component.scss';
import QRCode from 'react-qr-code';

interface IDepositModal2ComponentProps {
  closeDepositModal2: MouseEventHandler<HTMLButtonElement>;
  depositAddress: string | undefined;
  depositBlockchain: 'Counterparty' | 'Ethereum' | undefined;
  depositModal2Visibility: boolean;
  gasMinimumRequired: number | undefined;
}

export default function DepositModal2(props: IDepositModal2ComponentProps): JSX.Element {
  const {
    closeDepositModal2,
    depositAddress,
    depositBlockchain,
    depositModal2Visibility,
    gasMinimumRequired,
  } = props;
  const [isOpenState, setOpenState] = useState(false);
  const [qrcodeModalText, setQrcodeModalText] = useState<string>();
  const [isQrcodeModalOpen, setShowModal] = useState<boolean>(false);
  const [buttonIsVisible, setButtonIsVisible] = useState<boolean>(true);

  useEffect(() => {
    setOpenState(depositModal2Visibility);
  }, [depositModal2Visibility]);

  const copyAddressToClipboard = () => () => {
    if (depositAddress) {
      navigator.clipboard
        .writeText(depositAddress)
        .then(() => {
          setQrcodeModalText('was copied to your clipboard!');
          setShowModal(true);
        })
        .catch(() => {
          setQrcodeModalText('cannot be copied to your clipboard!');
          setShowModal(true);
        });
    } else {
      setQrcodeModalText(
        'The application encountered an unexpected error, sorry. Please, contact our support.'
      );
      setShowModal(true);
    }
  };

  const closeQRCodeModal = () => setShowModal(false);

  return (
    <Modal
      id="deposit-modal-2"
      show={isOpenState}
      onHide={closeDepositModal2}
      animation={false}
      centered
    >
      <Modal.Header>
        <Modal.Title>Deposit on {depositBlockchain}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {buttonIsVisible ? (
          <>
            <p>Please send BitCrystals to the following address.</p>
            <p>Amount to be paid:</p>
            <h6>{gasMinimumRequired} BitCrystals</h6>
          </>
        ) : (
          <>
            <p>
              Your payment is being processed. Depending on the blockchain, the transaction
              confirmation may take some time.
            </p>
            <p>
              Please refresh the page to see the updated BCYM balance. Once you have enough BCYM,
              you can proceed with the withdrawal.
            </p>
          </>
        )}
        {depositAddress ? (
          <>
            {buttonIsVisible && (
              <>
                <QRCode
                  id="deposit-qr-code"
                  level="Q"
                  onClick={copyAddressToClipboard()}
                  size={128}
                  value={depositAddress}
                />
                <p>{depositAddress}</p>
              </>
            )}
            {buttonIsVisible ? (
              <div id="buttons">
                <Button onClick={() => setButtonIsVisible(false)}>I made the payment</Button>
              </div>
            ) : null}
          </>
        ) : (
          <div id="spinner-container">
            <Spinner animation="grow" />
          </div>
        )}
        <Modal id="qr-code-modal" show={isQrcodeModalOpen} onHide={closeQRCodeModal}>
          <Modal.Header>
            <Modal.Title>Information</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {depositAddress} <br /> {qrcodeModalText}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeQRCodeModal}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeDepositModal2}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
