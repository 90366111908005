/* eslint-disable react/jsx-props-no-spreading */
import './transaction-details-component.scss';
import React, { useEffect, useState } from 'react';
import BootstrapTable, {
  ExpandColumnRendererProps,
  ExpandHeaderColumnRenderer,
} from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import { Col, Container, Row } from 'react-bootstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';
import Tools from '../../tools';
import { TransactionView } from '../../models/transaction-view-model';
import { Transactions } from '../../models/transactions-model';
import CustomSearch from '../../controls/custom-search/custom-search-control';
import noImageFound from '../../assets/images/no-image-found.jpg';
import counterpartyImage from '../../assets/images/blockchains/counterparty.png';
import klaytnImage from '../../assets/images/blockchains/klaytn.png';
import ethereumImg from '../../assets/images/blockchains/ethereum.png';
import 'lightgallery/scss/lightgallery.scss';

export default function TransactionDetails(params: { transactions: Transactions }): JSX.Element {
  const convertDate = (seconds: string): string => {
    const date: Date = new Date(0);
    date.setUTCSeconds(Number.parseInt(seconds, 10));
    return `${`0${date.getDate()}`.slice(-2)}-${`0${date.getMonth() + 1}`.slice(
      -2
    )}-${date.getFullYear()} ${`0${date.getHours()}`.slice(-2)}:${`0${date.getMinutes()}`.slice(
      -2
    )}`;
  };

  const imageFormatter = (cell: string) => {
    return (
      <LightGallery
        licenseKey={process.env.REACT_APP_LIGHTGALLERY_API_KEY}
        startAnimationDuration={0}
        speed={0}
        toggleThumb={false}
        backdropDuration={200}
        slideEndAnimation
        controls={false}
        counter={false}
        download={false}
      >
        <img height="70" src={cell} alt="Orb asset" />
      </LightGallery>
    );
  };

  const shortTextFormatter = (cell: string, row: TransactionView) => {
    const addLink = row.destination === cell || row.source === cell;
    if (cell?.length <= 7 && !addLink) {
      return <span>{cell}</span>;
    }

    const numberOfChars = addLink ? 5 : 8;
    const linkText = Tools.shortenText(cell, numberOfChars);

    return addLink ? (
      <>
        <Link to={`/address?address=${row.destination === cell ? row.destination : row.source}`}>
          {linkText}
        </Link>
        <br />
        {row.source === cell && <span>{row.sourceUsername}</span>}
        {row.destination === cell && <span>{row.destinationUsername}</span>}
      </>
    ) : (
      <span>{linkText}</span>
    );
  };

  const blockchainFormatter = (cell: string) => {
    let blockchainImage = noImageFound;
    if (cell === 'counterparty') {
      blockchainImage = counterpartyImage;
    } else if (cell === 'klaytn') {
      blockchainImage = klaytnImage;
    } else if (cell === 'ethereum') {
      blockchainImage = ethereumImg;
    }
    return (
      <div className="cell-blockchain">
        <img height="25" src={blockchainImage} alt="blockchain" />
        {cell}
      </div>
    );
  };

  const columns = [
    {
      dataField: 'image',
      text: 'Image',
      formatter: imageFormatter,
      headerStyle: { width: '10%' },
      sort: false,
    },
    {
      dataField: 'time',
      text: 'Time',
      sort: true,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'blockchain',
      formatter: blockchainFormatter,
      headerStyle: { width: '10%' },
      sort: true,
      text: 'Blockchain',
    },
    {
      dataField: 'collection',
      text: 'Collection',
      sort: true,
      headerStyle: { width: '15%' },
    },
    {
      dataField: 'contract',
      text: 'Contract',
      sort: true,
      headerStyle: { width: '16%' },
      formatter: shortTextFormatter,
    },
    {
      dataField: 'source',
      text: 'From',
      sort: true,
      headerStyle: { width: '10%' },
      formatter: shortTextFormatter,
    },
    {
      dataField: 'sourceUsername',
      text: 'From Username',
      sort: true,
      headerStyle: { width: 0, display: 'none' },
      style: { display: 'none' },
    },
    {
      dataField: 'destination',
      text: 'To',
      sort: true,
      headerStyle: { width: '10%' },
      formatter: shortTextFormatter,
    },
    {
      dataField: 'destinationUsername',
      text: 'To Username',
      sort: true,
      headerStyle: { width: 0, display: 'none' },
      style: { display: 'none' },
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      sort: true,
      headerStyle: { width: '9%' },
    },
  ];

  const columnHeaderRenderer = (props: ExpandHeaderColumnRenderer) => {
    if (props.isAnyExpands) {
      return <b style={{ cursor: 'pointer' }}>&nbsp;-&nbsp;</b>;
    }
    return <b style={{ cursor: 'pointer' }}>&nbsp;+&nbsp;</b>;
  };

  const columnRenderer = (props: ExpandColumnRendererProps) => {
    if (props.expanded) {
      return <b style={{ cursor: 'pointer' }}>&nbsp;-&nbsp;</b>;
    }
    return <b style={{ cursor: 'pointer' }}>&nbsp;...&nbsp;</b>;
  };

  const expandRow = {
    renderer(row: TransactionView) {
      return (
        <Container>
          <Row>
            <Col>Transaction hash:</Col>
            <Col>
              <a href={row.explorer + row.txId} target="_blank" rel="noreferrer">
                {row.txId}
              </a>
            </Col>
            <Col />
          </Row>
        </Container>
      );
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,

    expandHeaderColumnRenderer: columnHeaderRenderer,

    expandColumnRenderer: columnRenderer,
  };

  const [transactions, setTransactions] = useState<TransactionView[]>();

  useEffect(() => {
    const initTransactions = params;
    const items = initTransactions.transactions?.data?.map((t) => ({
      blockchain: t.blockchain,
      collection: t.orbs?.[0]?.collection?.name,
      contract: t.contract?.address,
      destination: t.destination,
      destinationUsername: t.destination_user?.spellsofgenesis.username ?? '',
      explorer: initTransactions.transactions?.meta?.activeBlockchains[t.blockchain]?.explorerTx,
      image: t.orbs?.[0]?.imageUrl?.includes('cryptokitties')
        ? noImageFound
        : null ??
          t.orbs?.[0]?.imageUrl ??
          t.orbs?.[0]?.imgURL ??
          t.orbs?.[0]?.fallbackImageUrl ??
          noImageFound,
      quantity: t.quantity,
      source: t.source,
      sourceUsername:
        t.source === '0x0000000000000000000000000000000000000000'
          ? 'issuance'
          : null ?? t.source_user?.spellsofgenesis.username ?? '',
      time: convertDate(t.timestamp),
      txId: t.txId,
    }));

    setTransactions(items);
  }, []);

  return (
    <div>
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            bootstrap4
            keyField="txId"
            data={transactions || []}
            columns={columns}
            search
          >
            {(props: any) => (
              <div>
                <PaginationListStandalone {...paginationProps} />
                <CustomSearch {...props.searchProps} />
                <BootstrapTable
                  bootstrap4
                  striped
                  hover
                  condensed
                  defaultSortDirection="asc"
                  expandRow={expandRow}
                  {...props.baseProps}
                  {...paginationTableProps}
                />
                <SizePerPageDropdownStandalone {...paginationProps} />
                <PaginationListStandalone {...paginationProps} />
              </div>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </div>
  );
}
