import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import AppComponent from './components/app/app-component';
import WalletComponent from './components/wallet/wallet-component';
import CollectionDetailsComponent from './components/collection-details/collection-details-component';
import CollectionsComponent from './components/collections/collections-component';
import MainMenuComponent from './components/main-menu/main-menu-component';

export default function AppRouter(): JSX.Element {
  return (
    <Router>
      <MainMenuComponent />
      <Routes>
        <Route path="/" element={<AppComponent />} />
        <Route path="/collections" element={<CollectionsComponent />} />
        <Route path="/collection" element={<CollectionDetailsComponent />} />
        <Route path="/address" element={<WalletComponent />} />
      </Routes>
    </Router>
  );
}
