import React from 'react';
import './custom-search-control.scss';
import { Button } from 'react-bootstrap';

export default function CustomSearch(props: { onSearch: any }): JSX.Element {
  let input: HTMLInputElement | null;
  const { onSearch } = props;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch(input && input.value);
    }
  };

  const handleClick = () => () => {
    onSearch(input && input.value);
  };

  return (
    <div id="table-filter" className="d-flex mb-2">
      <input
        className="form-control bg-light"
        type="text"
        placeholder="Filter..."
        ref={(n) => {
          input = n;
          return input;
        }}
        onKeyPress={handleKeyPress}
      />
      <Button onClick={handleClick()} variant="outline-secondary">
        <i className="icon-search4" />
      </Button>
    </div>
  );
}
