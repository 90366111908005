import React from 'react';
import './home-component.scss';

function HomeComponent(): JSX.Element {
  return (
    <div id="parallax">
      <div id="main-block">
        <div>
          <div>
            <div style={{ textAlign: 'center' }}>
              <p id="tagline">Orb Explorer: Your NFT Browser</p>
              <h1>Ethereum, Kusama, Counterparty, Klaytn</h1>
              <a href="collections" className="button">
                Browse NFTS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeComponent;
