export default class Tools {
  public static shortenText(text: string, numberOfChars?: number): string {
    const chars = numberOfChars ?? 8;
    if (text && text.length > chars * 2 + 1) {
      const result = `${text.substring(0, chars)}...${text.substring(text.length - chars)}`;
      return result;
    }

    return text;
  }

  public static getNetworkName(networkId?: number): string {
    switch (networkId) {
      case 1: {
        return 'ethereum';
      }
      case 8217: {
        return 'klaytn';
      }
      case -1: {
        return 'counterparty';
      }
      case -2: {
        return 'firstOasisKlaytn';
      }
      default: {
        return 'not supported';
      }
    }
  }

  public static getNetworkLongName(networkId?: number): string {
    switch (networkId) {
      case -2: {
        return 'First Oasis';
      }
      case -1:
      case 1:
      case 8217:
      default: {
        return Tools.getNetworkName(networkId);
      }
    }
  }
}
